import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Import from '../components/Import';
import Component from '../components/Component';
import Content, { HTMLContent } from '../components/Content';
import Tag from '../components/Tag';

declare global {
  interface Window {
    gaOptout: Function;
  }
}

export const ImpressumPageTemplate = ({ title, body, contentComponent }) => {
  const PostContent = contentComponent || Content;

  const handleOptout = () => {
    window.gaOptout && window.gaOptout();
  };

  return (
    <>
      <div style={{ marginBottom: '2rem' }}>
        <Import importString="React" path="react" />
      </div>

      <Component name={title}>
        <Tag tag="div">
          <PostContent content={body} />
        </Tag>
      </Component>
    </>
  );
};

const ImpressumPage = ({ data }) => {
  const { markdownRemark: page } = data;

  return (
    <>
      <SEO title={page.frontmatter.title} />

      <ImpressumPageTemplate
        title={page.frontmatter.title}
        body={page.html}
        contentComponent={HTMLContent}
      />
    </>
  );
};

export default ImpressumPage;

export const impressumPageQuery = graphql`
  query ImpressumPage {
    markdownRemark(frontmatter: { templateKey: { eq: "impressum-page" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
